import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Text, Image } from "../components/Core"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Contact from "../components/Services/contact"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import Button from "../components/Buttons/Button"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import MobileCarousel from "../components/Carousels/MobileCarousel"
import {
  faArrowRightLong,
  faArrowLeftLong
} from "@fortawesome/pro-solid-svg-icons"
import SizzleWildSmilesDesktop from "../videos/sizzle-wildsmiles-desktop.mp4"
import SizzleWildSmilesMobile from "../videos/sizzle-wildsmiles-mobile.mp4"
import Sizzle77PDesktop from "../videos/sizzle-77p-desktop.mp4"
import Sizzle77PMobile from "../videos/sizzle-77p-mobile.mp4"
import SizzleUtahDesktop from "../videos/sizzle-utah-desktop.mp4"
import SizzleUtahMobile from "../videos/sizzle-utah-mobile.mp4"
import SizzleRedoxDesktop from "../videos/sizzle-redox-desktop.mp4"
import SizzleRedoxMobile from "../videos/sizzle-redox-mobile.mp4"
import SizzleWuwta from "../videos/sizzle-wuwta.mp4"
import SizzleStemodontics from "../videos/sizzle-stemodontics.mp4"
import Video from "../components/Video/Video"

function SingleOurWork({ data }) {
  let post = data.allOurWorkJson.nodes[0]
  const {
    hero,
    aboutClient,
    branding,
    website,
    threeDAnimation,
    videoProduction,
    podcast,
    photoGallery
  } = post

  const ourWorkPages = data.ourWork.nodes

  const [currentSlide, setCurrentSlide] = useState(0)

  let clientsSliderRef = useRef(null)
  let sliderRef = useRef(null)

  const clientsSliderSettings = {
    className: "center",
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    dots: false,
    centerPadding: "0",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerPadding: "20px",
          centerMode: true
        }
      }
    ]
  }

  const settings = {
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    dots: false,
    infinite: false,
    centerPadding: "20px"
  }

  const handleAfterChange = current => {
    setCurrentSlide(current)
  }

  const getOtherWorksCard = (item, index) => (
    <div className="rounded sow__other-work-item" key={index}>
      <Image publicId={item.query.ourWorkSingleImages[0]} useAR={false} />
      <div className="sow__other-work-content">
        <Text as="h4" text={item.category} />
        <Button
          buttonText="Learn More"
          href={`/${item.title}`}
          contained
          className="sow__button-hover mt-1"
        />
      </div>
    </div>
  )

  const getDesktopVideoFileName = fileName => {
    if (fileName === "SizzleWildSmiles") {
      return SizzleWildSmilesDesktop
    } else if (fileName === "Sizzle77P") {
      return Sizzle77PDesktop
    } else if (fileName === "SizzleRedox") {
      return SizzleRedoxDesktop
    } else if (fileName === "SizzleUtah") {
      return SizzleUtahDesktop
    } else if (fileName === "SizzleStemodontics") {
      return SizzleStemodontics
    } else if (fileName === "SizzleWuwta") {
      return SizzleWuwta
    }
  }

  const getMobileVideoFileName = fileName => {
    if (fileName === "SizzleWildSmiles") {
      return SizzleWildSmilesMobile
    } else if (fileName === "Sizzle77P") {
      return Sizzle77PMobile
    } else if (fileName === "SizzleRedox") {
      return SizzleRedoxMobile
    } else if (fileName === "SizzleUtah") {
      return SizzleUtahMobile
    } else if (fileName === "SizzleStemodontics") {
      return SizzleStemodontics
    } else if (fileName === "SizzleWuwta") {
      return SizzleWuwta
    }
  }

  return (
    <Layout
      navSpacer
      theme="dark-teal"
      language="en"
      className="single-our-work sow">
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section>
        <Container>
          <div className="sow__heading">
            <div className="sow__heading-content">
              <Text
                as="p"
                text={hero.subheading}
                className="sow__heading-category"
              />
              <Text as="h1" text={hero.heading} className="mb-2" />
              <div className="is-hidden-desktop sow__heading-img">
                <Image
                  useAR={false}
                  publicId={hero.heroImageMobile}
                  wrapperClassName="mb-1 mt-1"
                />
              </div>
              <Text as="p" text={hero.blurb} className="mt-1" />
            </div>
            <div className="sow__heading-img is-hidden-touch">
              <Image useAR={false} publicId={hero.heroImage} />
            </div>
          </div>
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <div className="sow__client-heading">
            <div className="sow__client-heading-img">
              <Image
                publicId={aboutClient.desktopImage}
                wrapperClassName="is-hidden-touch"
              />
              <Image
                publicId={aboutClient.mobileImage}
                wrapperClassName="is-hidden-desktop"
              />
            </div>
            <div className="sow__client-heading-text">
              <Text as="h2" text={aboutClient.heading} />
              <Text as="p" text={aboutClient.blurb} className="mt-1" />
              {aboutClient.youtube && (
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${aboutClient.youtube}`}
                  language={"en"}
                  buttonClass="contained sow__button-hover mb-2"
                />
              )}
            </div>
          </div>
        </Container>
      </Section>

      {aboutClient.content && (
        <Section zeroTop>
          <Container>
            <div className="sow__client-content">
              <Text text={aboutClient.content} />
            </div>
          </Container>
        </Section>
      )}

      {branding.hasThisSection && (
        <>
          <Section className="is-hidden-touch">
            <Container>
              <div className="sow__branding">
                <Text as="h3" text={branding.heading} />
                <div>
                  <div className="sow__branding-sctn-1">
                    <div>
                      <Image publicId={branding.imageList[0]?.image} />
                      <div className="sow__branding-img-sctn mt-2">
                        <Image
                          publicId={branding.imageList[1]?.image}
                          useAR={false}
                        />
                        <Image
                          publicId={branding.imageList[2]?.image}
                          useAR={false}
                        />
                      </div>
                    </div>
                    <div>
                      <Text text={branding.textSection[0].text} />
                    </div>
                  </div>
                  <div className="sow__branding-arrow mt-1">
                    <UpRightArrow />
                  </div>
                </div>
                <div className="sow__branding-sctn-2">
                  <Text text={branding.textSection[1]?.text} />
                  <Image
                    publicId={branding.imageList[3]?.image}
                    useAR={false}
                  />
                </div>
              </div>
            </Container>
          </Section>
          <Section className="is-hidden-desktop">
            <Container>
              <Text as="h3" text={branding.heading} className="mb-2" />
            </Container>
            <div className="sow__branding-slider">
              <MobileCarousel>
                {branding.mobileImageList.map((item, index) => (
                  <div key={index}>
                    <Image publicId={item.image} useAR={false} />
                  </div>
                ))}
              </MobileCarousel>
            </div>
            <Container>
              {branding.textSection.map((item, index) => (
                <Text text={item.text} key={index} className="mt-1" />
              ))}
            </Container>
          </Section>
        </>
      )}

      {website.hasThisSection && (
        <Section>
          <Container>
            <div className="sow__website">
              <Text as="h3" text={website.heading} className="mb-2" />
              {website.imageList && (
                <Image publicId={website.imageList[0].image} />
              )}
              <div className="sow__website-content">
                <div className="sow__website-img-list">
                  {website.imageList &&
                    website.imageList.map(
                      (item, index) =>
                        index !== 0 && (
                          <div key={index}>
                            <Image
                              width="590"
                              height="331"
                              publicId={item.image}
                            />
                          </div>
                        )
                    )}
                </div>
                <Text text={website.content} />
              </div>
            </div>
          </Container>
        </Section>
      )}

      {photoGallery && photoGallery.length > 0 && (
        <Section>
          <Container className="no-padding-touch">
            <div>
              <Text
                as="h3"
                text="Photography"
                className="sow__gallery-heading"
              />
              <div className="sow__gallery">
                <button
                  aria-label="previous carousel slide"
                  className={`reset slider-arrow is-hidden-touch`}
                  onClick={() => clientsSliderRef.slickPrev()}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <div className="sow__gallery-slider">
                  <Slider
                    ref={slider => {
                      clientsSliderRef = slider
                    }}
                    {...clientsSliderSettings}>
                    {photoGallery.map((item, index) => (
                      <div key={index}>
                        <Image publicId={item.image} useAR={false} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <button
                  aria-label="next carousel slide"
                  className={`reset slider-arrow is-hidden-touch`}
                  onClick={() => clientsSliderRef.slickNext()}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <div className="sow__gallery-arrow-wrapper is-hidden-desktop">
                <button
                  aria-label="previous carousel slide"
                  className={`reset slider-arrow`}
                  onClick={() => clientsSliderRef.slickPrev()}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  aria-label="next carousel slide"
                  className={`reset slider-arrow`}
                  onClick={() => clientsSliderRef.slickNext()}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </Container>
        </Section>
      )}

      {threeDAnimation.hasThisSection && (
        <Section>
          <Container>
            <div className="sow__3d-animation">
              <Text as="h3" text={threeDAnimation.heading} className="mb-2" />
              <div className="sow__3d-animation-content">
                <div className="sow__3d-animation-image">
                  <Image
                    publicId={threeDAnimation.imageList[0].image}
                    useAR={false}
                  />
                  <Image
                    publicId={threeDAnimation.imageList[1].image}
                    useAR={false}
                    className="sow__3d-animation-image-2"
                  />
                </div>
                <Text
                  text={threeDAnimation.content}
                  className="sow__3d-animation-text"
                />
              </div>
            </div>
          </Container>
        </Section>
      )}

      {videoProduction.hasThisSection && (
        <Section className="sow__vp">
          <Container>
            <div>
              <Text as="h3" text={videoProduction.heading} className="mb-1" />
              {videoProduction.fileName && (
                <div className="mt-1 mb-2">
                  <div className="is-hidden-touch sow__vp-video rounded">
                    <Video
                      src={getDesktopVideoFileName(videoProduction.fileName)}
                    />
                  </div>
                  <div className="is-hidden-desktop mobile-sizzle rounded">
                    <Video
                      aspectRatio={
                        videoProduction.fileName === "Sizzle77P"
                          ? "177.25%"
                          : videoProduction.fileName === "SizzleWuwta" ||
                            videoProduction.fileName === "SizzleStemodontics"
                          ? "56%"
                          : "133%"
                      }
                      src={getMobileVideoFileName(videoProduction.fileName)}
                    />
                  </div>
                </div>
              )}
              <Text text={videoProduction.content} className="sow__vp-text" />
            </div>
          </Container>
        </Section>
      )}

      {podcast.hasThisSection && (
        <Section zeroTopMobile>
          <Container>
            <div className="rounded sow__podcast">
              <div className="sow__podcast-content">
                <Text text={podcast.content} />
                <Button
                  buttonText={podcast.button.buttonText}
                  href={podcast.button.href}
                  destination={podcast.button.destination}
                  contained
                  className="sow__button-hover"
                />
              </div>
              <div className="sow__podcast-image">
                <Image
                  wrapperClassName="is-hidden-touch"
                  publicId={podcast.desktopImage}
                />
                <Image
                  publicId={podcast.mobileImage}
                  wrapperClassName="is-hidden-desktop"
                />
              </div>
            </div>
          </Container>
        </Section>
      )}

      <Section className="is-hidden-touch">
        <Container>
          <div className="sow__other-work-first-row">
            <div className="sow__other-work-title-card">
              <Text
                as="h3"
                text="Transforming Businesses, <br>Driving Results"
              />
              <Image
                publicId={"NUVO/V4/success-metrics-nuvo-success.png"}
                useAR={false}
              />
            </div>
            {ourWorkPages
              .slice(0, 2)
              .map((item, index) => getOtherWorksCard(item, index))}
          </div>
          <div className="sow__other-work-grid">
            {ourWorkPages
              .slice(2)
              .map((item, index) => getOtherWorksCard(item, index + 2))}
          </div>
        </Container>
      </Section>

      <Section className="relative is-hidden-desktop">
        <Slider
          ref={slider => {
            sliderRef = slider
          }}
          {...settings}
          afterChange={handleAfterChange}>
          {ourWorkPages.map((item, index) => (
            <div className="rounded sow__ow-slider-card" key={index}>
              <Text
                as="h3"
                text="Transforming Businesses, <br>Driving Results"
              />
              <Image
                publicId={item.query.ourWorkSingleImages[1]}
                useAR={false}
              />
              <div className="sow__ow-slider-content">
                <Text as="h4" text={item.category} />
                <Button
                  buttonText="Learn More"
                  href={`/${item.title}`}
                  contained
                  className="sow__button-hover mt-1"
                />
              </div>
            </div>
          ))}
        </Slider>
        <div className="sow__ow-slider-card-img">
          <Image
            publicId={"NUVO/V4/success-metrics-nuvo-success.png"}
            useAR={false}
          />
        </div>
        <div
          className={`sow__ow-slider-arrow-wrapper  ${
            currentSlide === 0 ? "justify-end" : ""
          }`}>
          <button
            aria-label="previous carousel slide"
            className={`reset other-work-arrow ${
              currentSlide === 0 ? "disabled" : ""
            }`}
            onClick={() => sliderRef.slickPrev()}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          <button
            aria-label="next carousel slide"
            className={`reset other-work-arrow right ${
              currentSlide === ourWorkPages.length - 1 ? "disabled" : ""
            }`}
            onClick={() => sliderRef.slickNext()}>
            <FontAwesomeIcon icon={faArrowRightLong} />
          </button>
        </div>
      </Section>

      <Section zeroBottom>
        <Container>
          <Contact className="dark-theme" arrow={false} />
        </Container>
      </Section>
    </Layout>
  )
}

export default SingleOurWork

export const pageQuery = graphql`
  query singleWorkQuery($title: String!) {
    allOurWorkJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        hero {
          heading
          subheading
          blurb
          heroImage
          heroImageMobile
        }
        aboutClient {
          heading
          blurb
          desktopImage
          mobileImage
          content
          youtube
        }
        branding {
          hasThisSection
          heading
          imageList {
            image
          }
          mobileImageList {
            image
          }
          textSection {
            text
          }
        }
        website {
          hasThisSection
          heading
          content
          imageList {
            image
          }
        }
        videoProduction {
          hasThisSection
          heading
          blurb
          content
          fileName
        }
        threeDAnimation {
          hasThisSection
          heading
          content
          imageList {
            image
          }
        }
        podcast {
          content
          desktopImage
          mobileImage
          youtube
          hasThisSection
          button {
            buttonText
            href
            destination
          }
        }
        photoGallery {
          image
        }
      }
    }
    ourWork: allOurWorkJson(
      sort: { order: ASC, fields: order }
      filter: { title: { ne: $title }, hasThisPage: { eq: true } }
    ) {
      nodes {
        title
        category
        query {
          ourWorkSingleImages
        }
      }
    }
  }
`
