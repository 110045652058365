import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text, Image } from "../Core"
import Button from "../Buttons/Button"
import classNames from "classnames"

function Contact({ className, arrow = true }) {
  const data = useStaticQuery(graphql`
    query {
      allUniquePagesJson(filter: { title: { eq: "services/" } }) {
        nodes {
          cta {
            heading
            subheading
            desktopImage
            mobileImage
            buttons {
              button {
                buttonText
                href
                destination
              }
            }
          }
        }
      }
    }
  `)

  const { cta } = data.allUniquePagesJson.nodes[0]
  const mainClasses = classNames(className)
  return (
    <div className={`services__cta ${mainClasses}`}>
      <div className="services__cta-image is-hidden-touch">
        <Image width="496" height="676" publicId={cta.desktopImage} />
      </div>
      <div className="services__cta-content">
        <Text as="p" text={cta.heading} className="services__cta-heading" />
        <Text
          as="p"
          text={cta.subheading}
          className="services__cta-subheading"
        />
        <Button
          buttonText={cta.buttons[0].button.buttonText}
          href={cta.buttons[0].button.href}
          minimal
          arrow={arrow}
          className="services__cta-button"
        />
        <div className="services__cta-image is-hidden-desktop mt-2">
          <Image width="390" height="520" publicId={cta.mobileImage} />
        </div>
      </div>
    </div>
  )
}

export default Contact
