import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import Slider from "react-slick"
import "./MobileCarousel.scss"

const MobileCarousel = ({ children, arrows = true }) => {
  let sliderRef = useRef(null)
  const settings = {
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    dots: false,
    infinite: false,
    centerPadding: "20px"
  }
  return (
    <div className="slick-card-scale">
      <Slider
        ref={slider => {
          sliderRef = slider
        }}
        {...settings}>
        {children}
      </Slider>

      {arrows && (
        <div className="mc__btns">
          <button
            aria-label="Previous Slide"
            className="reset mc__btn left"
            onClick={() => sliderRef.slickPrev()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            aria-label="Next Slide"
            className="reset mc__btn right"
            onClick={() => sliderRef.slickNext()}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      )}
    </div>
  )
}

export default MobileCarousel
