import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause"

const Video = ({ src, aspectRatio = "56.25%" }) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const sizzleRef = useRef(null)

  const togglePlayPause = () => {
    if (isPlaying) {
      sizzleRef.current.pause()
      setIsPlaying(false)
    } else {
      sizzleRef.current.play()
      setIsPlaying(true)
    }
  }

  return (
    <div
      style={{
        position: "relative",
        height: 0,
        paddingTop: aspectRatio
      }}>
      <div
        className="img-loading"
        style={{
          position: "absolute",
          inset: 0
        }}></div>

      <video
        id="sizzle-player"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%"
        }}
        ref={sizzleRef}
        className="block"
        autoPlay
        loop
        muted
        playsInline
        width="100%">
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="play-pause-btn">
        <button
          className="reset"
          aria-label={isPlaying ? "pause video" : "play video"}
          onClick={togglePlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
      </div>
    </div>
  )
}

export default Video
